import React from "react";
import styles from "./coursemap.module.scss";
import ContactForm from "./contact-form";

export const CoursePopUp = ({ course, onClose }: any) => {
  return (
    <div
      className={styles.glass}
      //   style={{
      //     perspectiveOrigin: `${perspective.x}% ${perspective.y}%`,
      //     WebkitPerspective: 300,
      //     MozPerspective: 300,
      //     perspective: 300,
      //   }}
    >
      <button
        onClick={onClose}
        type="button"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 100,
          background: "none",
          border: "none",
          padding: "1.5rem",
          fontSize: ".8rem",
          cursor: "pointer",
          color: "black",
        }}
      >
        <img src="/images/close.png" alt="close" />
      </button>
      {course.type === "coming-soon" ? (
        <div style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        }}>
          <h1>Coming Soon</h1>
        </div>
      ) : (
        <div style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        }}>
          <h1 style={{color: 'black'}}>Contact Us</h1>
          <ContactForm />
        </div>
      )}
    </div>
  );
};
