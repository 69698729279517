import React from 'react';
import { useContext, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { CourseContext } from "./course-map";
import { FloatingTemple } from "./floating-temple";

let currentPos = 0;
let touchStartY = 0;

export const Islands = ({
  courses,
  setCurrentActorPoint,
}: {
  courses: any[];
  setCurrentActorPoint: (point: THREE.Vector3) => void;
}) => {
  const [path, setPath] = useState<THREE.CurvePath<THREE.Vector> | null>(null);
  const group = useRef<THREE.Group>(null);
  const [meshes, setMeshes] = useState<any[]>([]);

  useEffect(() => {
    // Your path creation logic will go here
    const points: any[] = [];
    const radius = 80 * courses?.length;
    const segments = courses?.length * 300;
    const amplitude = 60;
    const _meshes: any[] = [];

    for (let i = 0; i <= segments; i++) {
      const theta = (i / segments) * 2 * Math.PI; // Angle for the circular path
      const sineValue =
        amplitude * Math.sin(Math.ceil(courses.length / 2) * theta); // Adjusted sine wave

      //   const sineValue = amplitude * Math.sin(4 * theta); // Sine wave adjustment
      const adjustedRadius = radius + sineValue;

      // Convert polar coordinates to Cartesian coordinates
      const x = adjustedRadius * Math.cos(theta);
      const z = adjustedRadius * Math.sin(theta);

      points.push(new THREE.Vector3(x, 400, z));
      // Check if current point is a crest or trough and add a cube if it is
      if (i > 0 && i < segments) {
        const prevSineValue =
          amplitude *
          Math.sin(
            ((Math.ceil(courses.length / 2) * (i - 1)) / segments) * 2 * Math.PI
          );
        const nextSineValue =
          amplitude *
          Math.sin(
            ((Math.ceil(courses.length / 2) * (i + 1)) / segments) * 2 * Math.PI
          );
        if (
          (sineValue > prevSineValue && sineValue > nextSineValue) ||
          (sineValue < prevSineValue && sineValue < nextSineValue)
        ) {
          const xOffset =
            sineValue > prevSineValue && sineValue > nextSineValue ? -30 : 30;
          if (courses[_meshes.length]) {
            _meshes.push({
              position: new THREE.Vector3(x + xOffset, 380, z),
              scaling: new THREE.Vector3(8, 8, 8),
              name: `course-${_meshes.length}`,
              ...courses[_meshes.length],
            });
          }
        }
      }
    }

    const curve = new THREE.CurvePath();
    curve.add(new THREE.CatmullRomCurve3(points, true));
    setMeshes(_meshes);
    setPath(curve);
  }, [courses]);
  const { clicked } = useContext(CourseContext);

  useEffect(() => {
    if (path) {
      const pathLength = path.getLength();
      const handleScroll = (event: any) => {
        if (clicked) return;
        const direction = event.deltaY > 0 ? 20 : -20;
        currentPos += direction;
        if (currentPos < 0) currentPos = pathLength;
        if (currentPos > pathLength) currentPos = 0;
        currentPos = Math.min(Math.max(currentPos, 0), pathLength);

        const point = path.getPoint(
          (currentPos % pathLength) / pathLength
        ) as THREE.Vector3;
        if (point) {
          if (setCurrentActorPoint)
            setCurrentActorPoint(point as THREE.Vector3);
        }
      };
      window.addEventListener("wheel", handleScroll);

      const touchStartHandler = (event: any) => {
        if (clicked) return;
        touchStartY = event.touches[0].clientY;
      };

      const touchMoveHandler = (event: any) => {
        if (clicked) return;
        const deltaY = event.touches[0].clientY;
        const direction = deltaY > touchStartY ? 10 : -10;
        touchStartY = event.touches[0].clientY;
        currentPos += direction;
        if (currentPos < 0) currentPos = pathLength;
        if (currentPos > pathLength) currentPos = 0;
        currentPos = Math.min(Math.max(currentPos, 0), pathLength);

        const point = path.getPoint(
          (currentPos % pathLength) / pathLength
        ) as THREE.Vector3;
        if (point) {
          if (setCurrentActorPoint)
            setCurrentActorPoint(point as THREE.Vector3);
        }
      };

      const touchEndHandler = (event: any) => {
        if (clicked) return;
        touchStartY = 0;
      };
      window.addEventListener("touchstart", touchStartHandler);
      window.addEventListener("touchmove", touchMoveHandler);
      window.addEventListener("touchend", touchEndHandler);
      return () => {
        window.removeEventListener("wheel", handleScroll);
        window.removeEventListener("touchstart", touchStartHandler);
        window.removeEventListener("touchmove", touchMoveHandler);
        window.removeEventListener("touchend", touchEndHandler);
      };
    }
  }, [path, meshes, clicked]);

  return (
    <group ref={group}>
      <line color="#d9e7fc">{path && <primitive object={path} />}</line>
      {meshes.map(
        (course, i) =>
          course && <FloatingTemple key={i} index={i + 1} course={course} />
      )}
    </group>
  );
};