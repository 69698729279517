import React from "react";
import { ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import { useContext, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { lerp } from "three/src/math/MathUtils";
import { CourseContext } from "./course-map";
import { useGLTF, Text, Cloud, Clouds, useTexture } from "@react-three/drei";

function createRoundedRectShape(width: number, height: number, radius: number) {
  const shape = new THREE.Shape();
  shape.moveTo(radius, 0);
  shape.lineTo(width - radius, 0);
  shape.quadraticCurveTo(width, 0, width, radius);
  shape.lineTo(width, height - radius);
  shape.quadraticCurveTo(width, height, width - radius, height);
  shape.lineTo(radius, height);
  shape.quadraticCurveTo(0, height, 0, height - radius);
  shape.lineTo(0, radius);
  shape.quadraticCurveTo(0, 0, radius, 0);
  return shape;
}

export const FloatingTemple = ({ course, index }: any) => {
  const gltf = useGLTF("/models/ft.glb");
  const meshRef = useRef<THREE.Mesh>(null);
  const groupRef = useRef<THREE.Group>(null);
  const distance = useRef(0);
  // const roundedRectShape = createRoundedRectShape(5, 3, .2);
  const geometry = useMemo(
    () => new THREE.ShapeGeometry(createRoundedRectShape(5.2, 3.2, 0.2)),
    []
  );
  const buttonGeometry = useMemo(
    () => new THREE.ShapeGeometry(createRoundedRectShape(2, 0.5, 0.2)),
    []
  );
  const imageGeometry = useMemo(
    () => new THREE.ShapeGeometry(createRoundedRectShape(1, 1, 0.2)),
    []
  );
  const { setSelectedCourse, setClicked, setCurrentCourse, cameraLocation, setAudioPlaying } =
    useContext(CourseContext);

  const scaleFactor = useRef(0);
  const { camera } = useThree();

  useFrame(() => {
    if (meshRef.current && groupRef.current) {
      const position = new THREE.Vector3(
        meshRef.current.position.x - 10,
        meshRef.current.position.y + 15 - 60,
        meshRef.current.position.z - 180
      );
      distance.current = camera.position.distanceTo(position);
      const scale = Math.abs(distance.current) < 200 ? 1 : 0;
      scaleFactor.current = lerp(scaleFactor.current, scale, 0.1);

      groupRef.current.scale.set(
        scaleFactor.current,
        scaleFactor.current,
        scaleFactor.current
      );
    }
  });

  const [showStart, setShowStart] = useState(false);
  useFrame(() => {
    if (showStart && course && cameraLocation) {
      cameraLocation.current = cameraLocation.current.lerpVectors(
        cameraLocation.current,
        new THREE.Vector3(
          course.position.x + 20,
          course.position.y + 60,
          course.position.z - 80
        ),
        0.1
      );
      camera.position.set(
        cameraLocation.current.x,
        cameraLocation.current.y,
        cameraLocation.current.z
      );
      camera.lookAt(
        new THREE.Vector3(
          course.position.x + 20,
          course.position.y + 60,
          course.position.z
        )
      );
    }
  });

  const texture = useTexture((course.img || "/images/student.png") as string)

  return (
    <mesh
      key={course._id}
      position={course.position}
      scale={course.scaling}
      ref={meshRef}
      receiveShadow
      castShadow
    >
      <primitive object={gltf.scene.clone(true)} />
      <Clouds
        material={THREE.MeshBasicMaterial}
        position={[0, 10, 0]}
        castShadow
        receiveShadow
        texture="/textures/cloud.png"
      >
        <Cloud seed={1} scale={1} volume={1} color="#d9e7fc" fade={100} />
        <Cloud seed={1} scale={1} volume={1} color="white" fade={100} />
      </Clouds>
      <Clouds
        material={THREE.MeshBasicMaterial}
        position={[0, -1, 0]}
        castShadow
        receiveShadow
        texture="/textures/cloud.png"
      >
        <Cloud seed={1} scale={1} volume={1} color="#d9e7fc" fade={100} />
        <Cloud seed={1} scale={1} volume={1} color="white" fade={100} />
      </Clouds>
      <group
        position={[0, 0, 0]}
        ref={groupRef}
        onClick={(e: ThreeEvent<MouseEvent>) => {
          setClicked((prev) => !prev);
          setSelectedCourse && setSelectedCourse(course);
          setShowStart((prev) => !prev);
          e.stopPropagation();
        }}
      >
        <mesh
          geometry={geometry}
          position={[5, 4.7, 0]}
          rotation={[0, Math.PI, 0]}
          onPointerOver={() => {
            document.body.style.cursor = "pointer";
          }}
          onPointerLeave={() => {
            document.body.style.cursor = "default";
          }}
        >
          <meshPhysicalMaterial
            // transparent={true}
            // opacity={0.5}
            attach={"material"}
            transmission={0.4} // Determines how much light is transmitted through the material
            roughness={5} // A higher roughness value will simulate a frosted glass look
            metalness={0} // Glass is not metallic
            clearcoat={1} // Represents a clear coating on the material
            reflectivity={0.1} // Low reflectivity for frosted glass
            color="#80689f"
            // side={THREE.DoubleSide}
          />
          <Text
            color="white" // default
            anchorX="left" // default
            anchorY="middle" // default
            fontSize={0.29}
            position={[0.3, 2.7, 0.01]}
            // rotation={[0, Math.PI, 0]}
            font="/fonts/Roboto-Bold.ttf"
            maxWidth={4.5}
            whiteSpace="overflowWrap"
          >
            {course.name}
          </Text>
          <Text
            color="white" // default
            anchorX="left" // default
            anchorY="top" // default
            fontSize={0.13}
            position={[0.3, 2.4, 0.01]}
            // rotation={[0, Math.PI, 0]}
            whiteSpace="overflowWrap"
            maxWidth={4.2}
            font="/fonts/Roboto-Regular.ttf"
          >
            {course.description}
          </Text>
          <mesh
            geometry={buttonGeometry}
            position={[0.3, 0.45, 0.01]}
            //     rotation={[0, Math.PI, 0]}
            // scale={[., .1, .1]}
            onPointerOver={() => {
              document.body.style.cursor = "pointer";
            }}
            onPointerLeave={() => {
              document.body.style.cursor = "default";
            }}
            onClick={(e) => {
              setCurrentCourse(course);
              setClicked(true);
              setShowStart(true);
              if(setAudioPlaying) setAudioPlaying(true);
              e.stopPropagation();
            }}
          >
            <meshBasicMaterial
              // transparent={true}
              opacity={1}
              color="#7357FF"
              side={THREE.DoubleSide}
            />
            <Text
              fontSize={0.2}
              font="/fonts/Roboto-Regular.ttf"
              position={[1, 0.23, 0.03]}
              anchorX={"center"}
              anchorY={"middle"}
              // maxWidth={2}
              color={"white"}
            >
              {course.type === "coming-soon" ? "Coming Soon" : "Contact Us"}
            </Text>
          </mesh>

          <mesh geometry={imageGeometry} position={[0,2.9,0]} >
            <meshBasicMaterial attach="material" map={texture} transparent={true} polygonOffset polygonOffsetFactor={-20} />
          </mesh>
        </mesh>
      </group>
    </mesh>
  );
};
