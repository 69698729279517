/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/models/avatar.glb 
*/

import React, { useEffect, useMemo, useRef } from "react";
import { Html, useAnimations, useFBX, useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import useAxios from "axios-hooks";

const useAnimation = (root: React.RefObject<THREE.Group>) => {
  const { animations: idleAnimation } = useFBX("./models/Idle.fbx");
  const { animations: greetingAnimation } = useFBX("./models/Greeting.fbx");
  const { animations: pointingAnimation } = useFBX("./models/Pointing.fbx");

  useEffect(() => {
    idleAnimation[0].name = "idle";
    greetingAnimation[0].name = "greeting";
    pointingAnimation[0].name = "pointing";
    console.log("changed");
  }, [idleAnimation, greetingAnimation, pointingAnimation]);

  return useAnimations(
    [idleAnimation[0], greetingAnimation[0], pointingAnimation[0]],
    root
  );
};

const Animations = ({
  root,
  setActions,
}: {
  root: React.RefObject<THREE.Group>;
  setActions: (actions: { [x: string]: THREE.AnimationAction | null }) => void;
}) => {
  const { actions } = useAnimation(root);
  useEffect(() => {
    setActions(actions);
  }, [actions]);
  return <></>;
};

export default function Avatar({
  avatarId,
  _id,
  muted,
  setAudioPlaying,
  setAudioText,
  audioPlaying,
  audioText,
  ...props
}: JSX.IntrinsicElements["group"] & {
  avatarId: string;
  _id: string;
  muted?: boolean;
  setAudioPlaying?: (playing: boolean) => void;
  setAudioText?: (text: string) => void;
  audioPlaying?: boolean;
  audioText?: string;
}) {
  const groupRef = useRef<THREE.Group>(null);
  const config = {
    baseUrl: "https://test.ridikgrad.com",
  };
  const { nodes }: any = useGLTF(
    `https://models.readyplayer.me/${
      avatarId || "652d348f2b0b061b5bce9cd6.glb"
    }?morphTargets=Oculus Visemes`
  ); // useGLTF('./models/avatar1.glb')

  useEffect(() => {
    // console.log(nodes);
    groupRef.current?.children.forEach((child) => {
      if (child instanceof THREE.SkinnedMesh) {
        child.updateMorphTargets();
      }
    });
  }, [nodes]);
  // https://media.githubusercontent.com/media/readyplayerme/animation-library/master/masculine/fbx/idle/F_Standing_Idle_Variations_002.fbx

  const [animation, setAnimation] = React.useState("idle");

  const audio = useMemo(
    () => (_id ? new Audio(`${config.baseUrl}/api/get-audio?id=${_id}`) : null),
    [_id]
  );

  const [actions, setActions] = React.useState<{
    [x: string]: THREE.AnimationAction | null;
  }>({});

  const Ani = useMemo(() => {
    return <Animations root={groupRef} setActions={setActions} />;
  }, [groupRef.current]);

  const [playAudio, setPlayAudio] = React.useState(false);
  // const lipsync: any[] = [];
  // const text = {text: ""};

  const [{ data: lipsync, loading }, fetch] = useAxios(
    {
      url: `${config.baseUrl}/api/get-viseme?id=${_id}`,
      method: "GET",
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  const [{ data: text }, fetchText] = useAxios(
    {
      url: `${config.baseUrl}/api/get-audio-text?id=${_id}`,
      method: "GET",
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  useEffect(() => {
    if (_id) {
      fetch();
      fetchText();
    }
  }, [_id]);

  useEffect(() => {
    if (text && text.text) {
      setAudioText?.(text.text);
      setAudioPlaying?.(true);
    }
  }, [text]);

  useEffect(() => {
    setPlayAudio(!!audioPlaying)
  }, [audioPlaying])

  useEffect(() => {
    setAudioPlaying?.(playAudio);
  }, [playAudio]);

  useEffect(() => {
    if (!audio) return;
    if (!lipsync) return;
    if(loading) return;
    try {
      if (audioPlaying && audio) audio.play().catch((e) => {});
      else {
        audio.pause();
        setAudioPlaying?.(false);
      }
    } catch (e) {}

    return () => {
      if (audio) {
        audio.pause();
      }
    };
  }, [audioPlaying, audio, lipsync, loading]);

  const prevAnimation = usePrevious(animation);

  useEffect(() => {
    console.log(animation);
    // if(prevAnimation == animation) return;
    if (prevAnimation) {
      console.log(`removing ${prevAnimation}`);
      actions[prevAnimation]?.fadeOut(0.2).stop();
    }
    (actions[animation] || actions["idle"])?.fadeIn(0.2).play();
  }, [animation, prevAnimation, actions]);

  useEffect(() => {
    console.log("actions changed", actions);
  }, [actions]);

  const pollyToOculusMap: { [key: string]: string } = {
    p: "PP", // for phonemes like p, b, m
    t: "nn", // for phonemes like t, d, n, l
    S: "CH", // for phonemes like tS, dZ, S, Z
    T: "TH", // for phonemes like Θ, ð
    f: "FF", // for phonemes like f, v
    k: "kk", // for phonemes like k, g, ŋ, h
    i: "I", // for phonemes like i:, ɪ, ɪə, j
    r: "RR", // for phoneme  ɹ
    s: "SS", // for phonemes like s, z
    "@": "aa", // for phonemes like ə, əʊ, ɑː
    a: "aa", // for phonemes like æ, aɪ, aʊ
    e: "E", // for phonemes like eɪ, ɜː, ɛ, ɛə
    O: "O", // for phonemes like ɔː, ɔɪ, ɒ
    u: "U", // for phonemes like u:, ʊ, ʊə
    E: "E", // for phoneme  ʌ
    sil: "sil",
    // add other mappings here if necessary
  };
  const prevVisemeValue = useRef<string>("");
  useFrame(() => {
    // nodes.Wolf3D_Teeth.morphTargetInfluences[
    //   nodes.Wolf3D_Teeth.morphTargetDictionary[`viseme_sil`]
    // ] = 1;
    if (!audio || audio.paused) {
      if (animation != "idle") setAnimation("idle");
      setAudioPlaying?.(false);
      setAudioText?.("");
      return;
    }

    const currentAudioTime = audio.currentTime * 1000 - 50;
    const currentViseme = lipsync.find((x: any) => x.time >= currentAudioTime);
    if (!currentViseme) {
      // if (animation != 'pointing') setAnimation('pointing');
      return;
    }
    let newVisemeValue = currentViseme.value;
    if (currentViseme.bodyAnimation && currentViseme.bodyAnimation != animation)
      setAnimation(currentViseme.bodyAnimation);
    else if (!currentViseme.bodyAnimation && animation != "pointing")
      setAnimation("pointing");
    // else setAnimation('idle');
    Object.values(pollyToOculusMap).forEach((x: string) => {
      groupRef.current?.children.forEach((child) => {
        if (
          child instanceof THREE.SkinnedMesh &&
          child.morphTargetInfluences &&
          nodes.Wolf3D_Teeth.morphTargetDictionary[`viseme_${x}`] &&
          pollyToOculusMap[newVisemeValue] != x
        ) {
          child.morphTargetInfluences[
            nodes.Wolf3D_Teeth.morphTargetDictionary[`viseme_${x}`]
          ] = Math.max(
            0,
            child.morphTargetInfluences[
              nodes.Wolf3D_Teeth.morphTargetDictionary[`viseme_${x}`]
            ] - 0.1
          );
        }
      });
    });
    if (newVisemeValue != prevVisemeValue.current) {
      groupRef.current?.children.forEach((child) => {
        if (child instanceof THREE.SkinnedMesh && child.morphTargetInfluences) {
          child.morphTargetInfluences[
            nodes.Wolf3D_Teeth.morphTargetDictionary[
              `viseme_${pollyToOculusMap[newVisemeValue]}`
            ]
          ] = 0.1;
        }
      });
      prevVisemeValue.current = newVisemeValue;
    }
    groupRef.current?.children.forEach((child) => {
      if (child instanceof THREE.SkinnedMesh && child.morphTargetInfluences) {
        child.morphTargetInfluences[
          nodes.Wolf3D_Teeth.morphTargetDictionary[
            `viseme_${pollyToOculusMap[newVisemeValue]}`
          ]
        ] = Math.min(
          child.morphTargetInfluences[
            nodes.Wolf3D_Teeth.morphTargetDictionary[
              `viseme_${pollyToOculusMap[newVisemeValue]}`
            ]
          ] + 0.1,
          1
        );
      }
    });
  });

  useEffect(() => {
    if (audio) audio.muted = !!muted;
  }, [muted, audio]);

  return (
    <group
      {...props}
      key={avatarId}
      ref={groupRef}
      scale={[1.3, 1.3, 1.3]}
      onClick={() => {
        setPlayAudio(true);
      }}
    >
      <primitive object={nodes.Hips} />
      {Object.values(nodes).map((child: any) => {
        if (child instanceof THREE.SkinnedMesh) {
          return (
            <skinnedMesh
              key={child.name}
              geometry={child.geometry}
              material={child.material}
              skeleton={child.skeleton}
            />
          );
        }
      })}
      {Ani}
    </group>
  );
}

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// useGLTF.preload('./models/avatar1.glb')
