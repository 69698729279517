import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import {
  useGLTF,
  BakeShadows,
  Loader,
  Decal,
  useTexture,
} from "@react-three/drei";
import * as THREE from "three";
import styles from "./coursemap.module.scss";
import { TalkingBot } from "./talking-bot";
import { Islands } from "./islands";
import { HotAirBallon } from "./HotAirBallon";
import { CoursePopUp } from "./course-popup";

export const CourseContext = React.createContext<{
  clicked: boolean;
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
  currentCourse: any;
  setCurrentCourse: React.Dispatch<React.SetStateAction<any>>;
  cameraLocation?: React.MutableRefObject<THREE.Vector3>;
  selectedCourse?: any;
  setSelectedCourse?: React.Dispatch<React.SetStateAction<any>>;
  muted?: boolean;
  toggleMute?: () => void;
  audioText?: string;
  setAudioText?: React.Dispatch<React.SetStateAction<string>>;
  audioPlaying?: boolean;
  setAudioPlaying?: React.Dispatch<React.SetStateAction<boolean>>;
  avatarId?: string;
  setAvatarId?: React.Dispatch<React.SetStateAction<string>>;
}>({
  clicked: false,
  setClicked: () => {},
  currentCourse: null,
  setCurrentCourse: () => {},
});

const CourseProvider = ({ children }: { children: React.ReactNode }) => {
  const [clicked, setClicked] = useState(false);
  const [currentCourse, setCurrentCourse] = React.useState<any>({});
  const [selectedCourse, setSelectedCourse] = React.useState<any>({});
  const [muted, setMute] = React.useState(false);
  const [audioText, setAudioText] = React.useState("");
  const [audioPlaying, setAudioPlaying] = React.useState(false);
  const [avatarId, setAvatarId] = React.useState<string>(
    "652d348f2b0b061b5bce9cd6.glb"
  );
  const toggleMute = () => setMute((prev) => !prev);
  const cameraLocation = useRef<THREE.Vector3>(new THREE.Vector3(0, 0, 0));

  return (
    <CourseContext.Provider
      value={{
        clicked,
        setClicked,
        currentCourse,
        setCurrentCourse,
        cameraLocation,
        selectedCourse,
        setSelectedCourse,
        muted,
        toggleMute,
        audioPlaying,
        setAudioPlaying,
        audioText,
        setAudioText,
        avatarId,
        setAvatarId,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

const CourseMap = ({ training }: { training: { courses: any[] } }) => {
  return (
    <CourseProvider>
      <CourseScene training={training} key="scene" />
    </CourseProvider>
  );
};

export const getInitials = (str: string) => {
  const names = str?.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

const CourseScene = ({ training }: { training: { courses: any[] } }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [actorPoint, setActorPoint] = useState<THREE.Vector3>(
    new THREE.Vector3(0, 0, 0)
  );
  const { selectedCourse, muted, setAudioText, setAudioPlaying, currentCourse, setCurrentCourse } =
    useContext(CourseContext);

  return training && training.courses && training.courses.length > 0 ? (
    <div key={"training"}>
      <img src="/images/logo.png" alt="" className={styles.logo} />
      {/* {audioPlaying && <div className="speech-bubble">{audioText}</div>} */}
      {currentCourse?.name && (
        <CoursePopUp
          course={currentCourse}
          onClose={() => {
            setCurrentCourse({});
          }}
          onNext={function (): void {
            const courseData = training;
            const index =
              courseData.courses.indexOf(currentCourse) + 1 >=
              courseData.courses.length
                ? 0
                : courseData.courses.indexOf(currentCourse) + 1;
            setCurrentCourse(courseData.courses[index]);
            // guiState[index].metadata.moveTo();
          }}
          total={training.courses.length}
          current={training.courses.indexOf(currentCourse) + 1}
          onPrev={function (): void {
            const courseData = training;
            const index =
              courseData.courses.indexOf(currentCourse) - 1 < 0
                ? courseData.courses.length - 1
                : courseData.courses.indexOf(currentCourse) - 1;
            console.log(index);
            setCurrentCourse(courseData.courses[index]);

            // guiState[index].metadata.moveTo();
          }}
        />
      )}
      <Suspense fallback={<Loader />}>
        <Canvas
          camera={{ position: [0, 0, 0], fov: 50, near: 0.1, far: 1000000 }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100dvh",
            zIndex: -1,
          }}
          ref={canvasRef}
          onCreated={({ scene }) => {
            scene.fog = new THREE.FogExp2(0xcccccc, 0.0001); // color, density
          }}
        >
          <SkyDome />
          <hemisphereLight intensity={1} position={[0, 1, 0]} />
          <directionalLight
            castShadow
            position={[20, 10, 0]}
            intensity={5}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            shadow-camera-far={500}
            shadow-camera-left={-100}
            shadow-camera-right={100}
            shadow-camera-top={100}
            shadow-camera-bottom={-100}
          />
          <Islands
            courses={training.courses}
            setCurrentActorPoint={setActorPoint}
          />
          <Actor position={actorPoint} />
          <TalkingBot
            selectedCourse={selectedCourse}
            muted={muted}
            setAudioPlaying={setAudioPlaying}
            setAudioText={setAudioText}
          />
          <BakeShadows />
        </Canvas>
      </Suspense>
    </div>
  ) : (
    <></>
  );
};

function Actor({ position }: { position: THREE.Vector3 }) {
  const modelRef = useRef<THREE.Mesh>(null);
  // const gltf = useGLTF("/models/actor.glb"); // replace with your model path
  const { clicked, cameraLocation } = useContext(CourseContext);
  const texture = useTexture("/images/logo.png");
 
  useFrame(({ camera }) => {
    if (modelRef.current && !clicked && cameraLocation) {
      // Calculate the point at which the model should be, based on the scroll progress

      // lerp
      cameraLocation.current = cameraLocation.current.lerpVectors(
        cameraLocation.current,
        new THREE.Vector3(position.x + 10, position.y + 60, position.z - 180),
        0.1
      );
      camera.position.set(
        cameraLocation.current.x,
        cameraLocation.current.y,
        cameraLocation.current.z
      );
      modelRef.current.position.set(
        cameraLocation.current.x - 10,
        cameraLocation.current.y + 15 - 60,
        cameraLocation.current.z + 180
      );
      camera.lookAt(modelRef.current.position);
    }
  });

  return (
    <HotAirBallon position={[1,1,1]} ref={modelRef} receiveShadow castShadow />
  );
}

function SkyDome({ url = "/images/sky4.jpg" }: { url?: string }) {
  const texture = useLoader(THREE.TextureLoader, url);
  // url is the path to your equirectangular image
  const { scene } = useThree();

  useEffect(() => {
    const geometry = new THREE.SphereGeometry(2500, 60, 40);
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.BackSide,
    });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);
    return () => {
      scene.remove(mesh);
    }; // Cleanup on unmount
  }, [scene, texture]);

  return null; // This component doesn't render anything itself
}

export default CourseMap;
useGLTF.preload("/models/ft.glb");
useGLTF.preload("/models/actor.glb");
