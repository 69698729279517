import React from 'react';
import { ScreenSpace } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useContext, useRef } from "react";
import Avatar from "./AvatarModel";
import { CourseContext } from "./course-map";
import * as THREE from "three";

export const TalkingBot = ({
    selectedCourse,
    muted,
    setAudioText,
    setAudioPlaying,
  }: any) => {
    const { size } = useThree();
    const ref = useRef<THREE.Group>(null);
  
    const {clicked, avatarId, audioPlaying, audioText} = useContext(CourseContext);
  
    useFrame(() => {
      if (ref.current) {
        const aspect = size.width / size.height;
        const viewportHeight = 2 * Math.tan((50 * Math.PI) / 180 / 2) * 10;
        const viewportWidth = viewportHeight * aspect;
  
        // These will be in Three.js units
        const charWidth = 3; /* width of your character in Three.js units */
        const charHeight = 0.2; /* height of your character in Three.js units */
  
        // Adjust these values to set the character's position relative to the bottom right
        ref.current.position.x = viewportWidth / 2 - charWidth / 2;
        ref.current.position.y = -viewportHeight / 2 + charHeight / 2;
        if(clicked) {
          ref.current.position.y = -viewportHeight * 3 / 4 + (charHeight * 3) / 2 ;
          ref.current.scale.set(3, 3, 3);
        } else {
          ref.current.position.y = -viewportHeight / 2 + charHeight / 2;
          ref.current.scale.set(1,1,1);
        }
      }
    });
    return (
      <ScreenSpace depth={10}>
        <group ref={ref} key={"wrapper"}>
          <Avatar
            avatarId={avatarId || "652d348f2b0b061b5bce9cd6.glb"}
            _id={selectedCourse?._id || "welcome-default"}
            // position={[size.width / 200 , -(size.height / 200), 0]}
            rotation={[0, -Math.PI / 5, 0]}
            muted={muted}
            setAudioText={setAudioText}
            setAudioPlaying={setAudioPlaying}
            audioPlaying={audioPlaying}
            audioText={audioText}
          />
        </group>
      </ScreenSpace>
    );
  };