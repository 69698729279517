import React from 'react';
import './App.css';
import CourseMap from './course-map';

function App() {
  return (
    <div className="App">
      <CourseMap training={{courses: [
        {
          name: "Custom AI Solution Development",
          _id: "custom-ai-solution-development",
          description: "At helloalex.ai, we specialize in developing bespoke AI solutions tailored to your unique business challenges. Our team of expert AI engineers and data scientists works closely with you to design, build, and deploy intelligent systems that not only streamline operations but also unlock new opportunities for innovation and growth. From concept to code, we ensure your vision becomes a reality, delivering custom AI that sets you apart in the marketplace.",
          img: '/images/custom-ai-solution-development.png'
        },
        {
          name: "AI Project Management and Implementation",
          _id: "ai-project-management-and-implementation",
          description: "Our AI Project Management and Implementation service is the cornerstone of your AI journey. We guide you through every phase of your AI project with a meticulous approach that ensures efficiency and effectiveness. Our seasoned project managers are adept at navigating the complexities of AI integration, overseeing the orchestration of data, technology, and teams to deliver your AI solutions on time and within budget. With helloalex.ai, your AI initiatives are in capable hands, leading to seamless implementation and optimal outcomes.",
          img: '/images/ai-project-management-and-implementation.png'
        },
        {
          name: "AI Consulting and Advisory Services",
          _id: "ai-consulting-and-advisory-services",
          description: "Navigate the AI landscape with confidence through helloalex.ai's Consulting and Advisory Services. Our consultants possess deep industry knowledge and AI expertise, offering strategic insights that align with your business objectives. We help you identify the most valuable AI opportunities, assess potential ROI, and devise a clear, actionable roadmap for AI adoption and scale. Whether you're taking your first steps in AI or looking to enhance existing capabilities, our bespoke advice positions you at the forefront of AI innovation.",
          img: '/images/ai-consulting-and-advisory-services.png'
        },
        {
          name: "AI Training and Capability Building",
          _id: "ai-training-and-capability-building",
          type: "coming-soon",
          description: "Empower your team with the skills and knowledge to thrive in the age of AI with helloalex.ai's Training and Capability Building service. Our comprehensive training programs are designed to upskill your workforce, from foundational AI concepts to advanced machine learning techniques. We offer a range of modules suited for various roles within your organization, ensuring that your team is equipped to support and sustain your AI solutions. With our expert-led training, foster a culture of continuous learning and development, ensuring your business remains competitive in a rapidly evolving digital world.",
          img: '/images/ai-training-and-capability-building.png'
        },
        {
          name: "Metaverse & Game Development",
          _id: "metaverse-and-game-development",
          description: "helloalex.ai is a leading developer of immersive experiences for the metaverse. We create virtual worlds that are rich in detail and interactivity, offering players a truly engaging and memorable experience. Our team of expert designers and developers work closely with you to bring your vision to life, delivering a bespoke metaverse solution that sets you apart in the marketplace. From concept to code, we ensure your metaverse experience is a success, delivering a product that captivates and delights.",
          img: '/images/metaverse-and-game-development.png'
        }
      ]}}/>
    </div>
  );
}

export default App;
