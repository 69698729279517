// ContactForm.js
import axios from "axios";
import { useState } from "react";

export function ContactForm() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phoneNumber: "",
    message: "",
  });
  const [message, setMessage] = useState("");
  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      setMessage("Sending...");
      const response = await axios.post("mail.php", formData);
      console.log(response.data);
      setMessage("Message sent successfully!");
    } catch (error) {
      console.error("Sending message failed.", error);
      setMessage("Message failed to send.");
    }
  };

  return message ? (
    message == 'Sending' ? <img src="/images/sending.gif" alt="loading" /> :
    <h1>{message}</h1>
  ) : (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flex: 1,
        width: "90%",
        marginTop: "2rem",
      }}
      onSubmit={handleSubmit}
    >
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
        required
        style={{
          padding: "1rem",
          marginBottom: "1rem",
          background: "#ffffff20",
          borderRadius: 10,
          border: "lightgray 1px solid"
        }}
      />
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Name"
        required
        style={{
          padding: "1rem",
          marginBottom: "1rem",
          background: "#ffffff20",
          borderRadius: 10,
          border: "lightgray 1px solid"
        }}
      />
      <input
        type="tel"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        placeholder="Phone Number"
        style={{
          padding: "1rem",
          marginBottom: "1rem",
          background: "#ffffff20",
          borderRadius: 10,
          border: "lightgray 1px solid"
        }}
      />
      <textarea
        name="message"
        value={formData.message}
        onChange={handleChange}
        placeholder="Your message"
        required
        style={{
          padding: "1rem",
          marginBottom: "1rem",
          background: "#ffffff20",
          borderRadius: 10,
          border: "lightgray 1px solid"
        }}
      />
      <button
        type="submit"
        style={{
          marginBottom: "1rem",
          maxWidth: "150px",
          alignSelf: "flex-end",
          backgroundColor: "#7357FF",
          borderRadius: 10,
          padding: "1rem",
          border: "lightgray 1px solid"
        }}
      >
        Send
      </button>
    </form>
  );
}

export default ContactForm;
