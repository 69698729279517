import React, { forwardRef, useRef } from "react";
import { Decal, useGLTF, useTexture } from "@react-three/drei";

function HotAirBallonComponent({ decals, innerRef, ...props }: any) {
  const { nodes, materials }: any = useGLTF("/models/actor.glb");
  const texture = useTexture("/images/logo.png");

  return (
    <group {...props} ref={innerRef} dispose={null}>
      <group position={[0, 0, 0]} scale={1}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007.geometry}
          material={materials["Material.018"]}
        ></mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_1.geometry}
          material={materials["Material.021"]}
        ></mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_2.geometry}
          material={materials["Material.020"]}
        >
          <Decal
            map={texture}
            position={[0, 15,5]}
            polygonOffsetFactor={-1} // The mesh should take precedence over the original
            scale={[15, 5, 15]}
            rotation={[0, Math.PI, 0]}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_3.geometry}
          material={materials["Material.019"]}
        ></mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_4.geometry}
          material={materials["gray.004"]}
        ></mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_5.geometry}
          material={materials["metalgrey.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_6.geometry}
          material={materials["white.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube007_7.geometry}
          material={materials["metal.004"]}
        />
      </group>
    </group>
  );
}

export const HotAirBallon = forwardRef((props: any, ref) => (
  <HotAirBallonComponent {...props} innerRef={ref} />
));
useGLTF.preload("/models/actor.glb");
